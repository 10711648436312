export const connectorLocalStorageKey = 'connectorIdv2';
export const walletLocalStorageKey = 'wallet';

export const apiUrl = process.env.NEXT_PUBLIC_API || '';

export const isDev = process.env.NODE_ENV === 'development';

export const paypalClientId =
  process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID ||
  'AfW_MBMFLRovJWr5hN3giEsxtuBdg9-ZN-RDr86xp1bb0zVtU3OUNZcYRSkdHevs4wbuewCch5IfI55C';

export const slugItem = 'nft-item';

export const NETWORK = process.env.NEXT_PUBLIC_NETWORK || 'mumbai';
export const NETWORK_ID = process.env.NEXT_PUBLIC_NETWORK_ID || '80001';

export const MAXIMUM_ITEMS = process.env.NEXT_PUBLIC_MAXIMUM_ITEMS || 1;
export const NEXT_PUBLIC_ENV = process.env.NEXT_PUBLIC_ENV ?? 'DEVELOP';
